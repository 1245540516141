/*
 |-----------------------------------------------------------------------------
 | components/layouts/Blocks/Blocks.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

import { get, isEmpty, map } from 'lodash';
import dynamic from 'next/dynamic';
// import { ray } from 'node-ray/web';

import { collectionSelector } from '@/features/collection';
import { pageSelector } from '@/features/page';
import { useAppSelector } from '@/app/hooks';

import * as IBlocks from './types';

const About = dynamic(() => import('@/organisms/About'));
const Alert = dynamic(() => import('@/molecules/Alert'));
const Articles = dynamic(() => import('@/organisms/Articles'));
const ArticlesLatest = dynamic(() => import('@/organisms/ArticlesLatest'));
const ArticlesOther = dynamic(() => import('@/organisms/ArticlesOther'));
const Banner = dynamic(() => import('@/organisms/Banner'));
const Body = dynamic(() => import('@/organisms/Body'));
const CallToAction = dynamic(() => import('@/organisms/CallToAction'));
const Certifications = dynamic(() => import('@/organisms/Certifications'));
const ContactInformation = dynamic(
	() => import('@/organisms/ContactInformation')
);
const Countdown = dynamic(() => import('@/organisms/Countdown'));
const Events = dynamic(() => import('@/organisms/Events'));
const EventsLatest = dynamic(() => import('@/organisms/EventsLatest'));
const EventsOther = dynamic(() => import('@/organisms/EventsOther'));
const Faqs = dynamic(() => import('@/organisms/Faqs'));
const Feature = dynamic(() => import('@/organisms/Feature'));
const Gallery = dynamic(() => import('@/organisms/Gallery'));
const Hero = dynamic(() => import('@/organisms/Hero'));
const Jobs = dynamic(() => import('@/organisms/Jobs'));
const JobsLatest = dynamic(() => import('@/organisms/JobsLatest'));
const JobsOther = dynamic(() => import('@/organisms/JobsOther'));
const Kinds = dynamic(() => import('@/organisms/Kinds'));
const Patrons = dynamic(() => import('@/organisms/Patrons'));
const People = dynamic(() => import('@/organisms/People'));
const PeopleFeatured = dynamic(() => import('@/organisms/PeopleFeatured'));
const Posts = dynamic(() => import('@/organisms/Posts'));
const PostsLatest = dynamic(() => import('@/organisms/PostsLatest'));
const PostsOther = dynamic(() => import('@/organisms/PostsOther'));
const PricingTable = dynamic(() => import('@/organisms/PricingTable'));
const ProductInformation = dynamic(
	() => import('@/organisms/ProductInformation')
);
const Products = dynamic(() => import('@/organisms/Products'));
const ProductsFeatured = dynamic(() => import('@/organisms/ProductsFeatured'));
const ProductsOther = dynamic(() => import('@/organisms/ProductsOther'));
const Projects = dynamic(() => import('@/organisms/Projects'));
const ProjectsLatest = dynamic(() => import('@/organisms/ProjectsLatest'));
const ProjectsOther = dynamic(() => import('@/organisms/ProjectsOther'));
const Recipes = dynamic(() => import('@/organisms/Recipes'));
const RecipesFeatured = dynamic(() => import('@/organisms/RecipesFeatured'));
const RecipesOther = dynamic(() => import('@/organisms/RecipesOther'));
const Results = dynamic(() => import('@/organisms/Results'));
const Reviews = dynamic(() => import('@/organisms/Reviews'));
const ReviewsLatest = dynamic(() => import('@/organisms/ReviewsLatest'));
const Services = dynamic(() => import('@/organisms/Services'));
const ServicesFeatured = dynamic(() => import('@/organisms/ServicesFeatured'));
const ServicesOther = dynamic(() => import('@/organisms/ServicesOther'));
const Social = dynamic(() => import('@/organisms/Social'));
const Specialties = dynamic(() => import('@/organisms/Specialties'));
const Stockists = dynamic(() => import('@/organisms/Stockists'));
const Subscriptions = dynamic(() => import('@/organisms/Subscriptions'));
const Usps = dynamic(() => import('@/organisms/Usps'));
const Values = dynamic(() => import('@/organisms/Values'));

const Blocks: FC<IBlocks.IProps> = ({ blocks }) => {
	// ray('Debug layout Blocks:', {
	// 	blocks: blocks,
	// }).orange();

	const collection = useAppSelector(collectionSelector);
	const page = useAppSelector(pageSelector);

	if (isEmpty(blocks)) {
		return (
			<Alert
				isDismissible
				text={`No blocks have been added to this ${collection}`}
				variant="warning"
			/>
		);
	}

	return map(blocks, (block, index) => {
		switch (get(block, '__typename')) {
			case `${collection}BlocksAbout`:
				return (
					<About
						key={`block-${index}__${get(block, '__typename')}`}
						actions={get(block, 'actions')}
						content={get(block, 'content')}
						image={get(block, 'image')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksArticles`:
				return (
					<Articles
						key={`block-${index}__${get(block, '__typename')}`}
						categories={get(block, 'categories')}
						content={get(block, 'content')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksArticlesLatest`:
				return (
					<ArticlesLatest
						key={`block-${index}__${get(block, '__typename')}`}
						actions={get(block, 'actions')}
						content={get(block, 'content')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksArticlesOther`:
				return (
					<ArticlesOther
						key={`block-${index}__${get(block, '__typename')}`}
						actions={get(block, 'actions')}
						content={get(block, 'content')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksBanner`:
				return (
					<Banner
						key={`block-${index}__${get(block, '__typename')}`}
						actions={get(block, 'actions')}
						image={get(block, 'image') || get(page, 'image')}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title') || get(page, 'title')}
						variant={get(block, 'variant')}
					/>
				);
			case `${collection}BlocksBody`:
				return (
					<Body
						key={`block-${index}__${get(block, '__typename')}`}
						readingTime={get(block, 'readingTime')}
						rows={get(block, 'rows')}
						tinaField={`blocks.${index}`}
					/>
				);
			case `${collection}BlocksBodyMk2`:
				return (
					<Body
						key={`block-${index}__${get(block, '__typename')}`}
						readingTime={get(block, 'readingTime')}
						rows={get(block, 'rows')}
						tinaField={`blocks.${index}`}
					/>
				);
			case `${collection}BlocksCallToAction`:
				return (
					<CallToAction
						key={`block-${index}__${get(block, '__typename')}`}
						image={get(block, 'image')}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksCertifications`:
				return (
					<Certifications
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksContactInformation`:
				return (
					<ContactInformation
						key={`block-${index}__${get(block, '__typename')}`}
						form={get(block, 'form')}
						rows={get(block, 'rows')}
						tinaField={`blocks.${index}`}
					/>
				);
			case `${collection}BlocksCountdown`:
				return (
					<Countdown
						key={`block-${index}__${get(block, '__typename')}`}
						date={get(block, 'date')}
						image={get(block, 'image')}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksEvents`:
				return (
					<Events
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksEventsLatest`:
				return (
					<EventsLatest
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksEventsOther`:
				return (
					<EventsOther
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksFaqs`:
				return (
					<Faqs
						key={`block-${index}__${get(block, '__typename')}`}
						faqs={get(block, 'faqs')}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksFeature`:
				return (
					<Feature
						key={`block-${index}__${get(block, '__typename')}`}
						collection={collection}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksGallery`:
				return (
					<Gallery
						key={`block-${index}__${get(block, '__typename')}`}
						images={get(block, 'images')}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksHero`:
				return (
					<Hero
						key={`block-${index}__${get(block, '__typename')}`}
						banners={get(block, 'banners')}
						tinaField={`block.${index}`}
					/>
				);
			case `${collection}BlocksJobs`:
				return (
					<Jobs
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksJobsLatest`:
				return (
					<JobsLatest
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksJobsOther`:
				return (
					<JobsOther
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksKinds`:
				return (
					<Kinds
						key={`block-${index}__${get(block, '__typename')}`}
						kinds={get(block, 'kinds')}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksPatrons`:
				return (
					<Patrons
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksPeople`:
				return (
					<People
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksPeopleFeatured`:
				return (
					<PeopleFeatured
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksPosts`:
				return (
					<Posts
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksPostsLatest`:
				return (
					<PostsOther
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksPostsOther`:
				return (
					<PostsLatest
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksPricingTable`:
				return (
					<PricingTable
						key={`block-${index}__${get(block, '__typename')}`}
						plans={get(block, 'plans')}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksProductInformation`:
				return (
					<ProductInformation
						key={`block-${index}__${get(block, '__typename')}`}
						rows={get(block, 'rows')}
						tinaField={`blocks.${index}`}
					/>
				);
			case `${collection}BlocksProducts`:
				return (
					<Products
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksProductsFeatured`:
				return (
					<ProductsFeatured
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksProductsOther`:
				return (
					<ProductsOther
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksProjects`:
				return (
					<Projects
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksProjectsLatest`:
				return (
					<ProjectsLatest
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksProjectsOther`:
				return (
					<ProjectsOther
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksRecipes`:
				return (
					<Recipes
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksRecipesFeatured`:
				return (
					<RecipesFeatured
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksRecipesOther`:
				return (
					<RecipesOther
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksResults`:
				return (
					<Results
						key={`block-${index}__${get(block, '__typename')}`}
						results={get(block, 'results')}
					/>
				);
			case `${collection}BlocksReviews`:
				return (
					<Reviews
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksReviewsLatest`:
				return (
					<ReviewsLatest
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksServices`:
				return (
					<Services
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksServicesFeatured`:
				return (
					<ServicesFeatured
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksServicesOther`:
				return (
					<ServicesOther
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksSocial`:
				return (
					<Social
						key={`block-${index}__${get(block, '__typename')}`}
						image={get(block, 'image')}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksSpecialties`:
				return (
					<Specialties
						key={`block-${index}__${get(block, '__typename')}`}
						specialties={get(block, 'specialties')}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksStockists`:
				return (
					<Stockists
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksSubscriptions`:
				return (
					<Subscriptions
						key={`block-${index}__${get(block, '__typename')}`}
						form={get(block, 'form')}
						image={get(block, 'image')}
						rows={get(block, 'rows')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
					/>
				);
			case `${collection}BlocksUsps`:
				return (
					<Usps
						key={`block-${index}__${get(block, '__typename')}`}
						tinaField={`blocks.${index}`}
						usps={get(block, 'usps')}
					/>
				);
			case `${collection}BlocksValues`:
				return (
					<Values
						key={`block-${index}__${get(block, '__typename')}`}
						text={get(block, 'text')}
						tinaField={`blocks.${index}`}
						title={get(block, 'title')}
						values={get(block, 'values')}
					/>
				);
			default:
				return (
					<Alert
						isDismissible
						text={`The block ${get(
							block,
							'__typename'
						)} could not be found`}
						variant="error"
					/>
				);
		}
	});
};

export default Blocks;
